.logo {
    background-size: cover;
    border-radius: 50%;
    background-color: #dcdcdc;
}

.stretch {
    display: flex;
    justify-content: center;
}

.history {
    width: 100%;
    border-collapse: collapse;
}

.history tr>* {
    border: solid 1px #000000
}

td.top {
    vertical-align: top;
}

@media (max-width: 500px) {
    html {
        font-size: 7px!important;
    }
    button {
        font-size: 5px!important;
    }
}