body {
    margin: 0;
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(roboto.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: RB;
    src: url(Roboto-Bold.ttf);
}


body {
    padding: 0
}

#map > * > *, #map > * > * > * {
    border-radius: 4px!important;
}

h3 {line-height: 1}

iframe.youtube {
    width: 100%;
    height: 100%;
    min-height: 400px;
    margin-bottom: 40px!important;
    border-radius: 4px!important;
}

h1,h2 {text-align: center}


@media (min-width: 1px) {
    h1 {font-size: 20px!important;}
    h2 {font-size: 15px!important;}
    h3 {font-size: 10px!important;}
    .section > * {margin-bottom: 40px!important;}
}

@media (min-width: 320px) {
    h1 {font-size: 30px!important;}
    h2 {font-size: 25px!important;}
    h3 {font-size: 20px!important;}
    .section > * {margin-bottom: 20px!important}
}

@media (min-width: 375px) {
    h1 {font-size: 35px!important;}
    h2 {font-size: 30px!important;}
    h3 {font-size: 25px!important;}
    .section > * {margin-bottom: 20px!important}
}

@media (min-width: 425px) {
    h1 {font-size: 40px!important;}
    h2 {font-size: 30px!important;}
    h3 {font-size: 20px!important;}
    .section > * {margin-bottom: 20px!important}
}

@media (min-width: 768px) {
    h1 {font-size: 50px!important;}
    h2 {font-size: 40px!important;}
    h3 {font-size: 30px!important;}
    .section > * {margin-bottom: 20px!important}
}

@media (min-width: 1024px) {
    h1 {font-size: 70px!important; margin-top: 30px!important;}
    h2 {font-size: 60px!important;}
    h3 {font-size: 30px!important;}
    .section > * {margin-bottom: 40px!important}
}

@media (min-width: 1440px) {
    h1 {font-size: 80px!important;}
    h2 {font-size: 60px!important;}
    h3 {font-size: 30px!important;}
    .section > * {margin-bottom: 50px!important}
}


@media print {.noPrint{display: none}}