.logo {
    width: 100px;
    height: 100px;
    background-size: cover;
    border-radius: 50%;
    background-color: #dcdcdc;
}

.history {
    width: 100%;
    border-collapse: collapse;
}

.history tr>* {
    border: solid 1px #000000
}

td.top {
    vertical-align: top;
}